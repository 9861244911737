import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { getChapter } from '../Actions'
import DOMPurify from 'dompurify'

function NewTestamentPortion() {
  const newTestamentAnnualDevotions = [
    {
      month: 0,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 0,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 0,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 0,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 1,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 1,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 1,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 1,
      start_day: 22,
      end_day: 29,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 2,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 2,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 2,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 2,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 3,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 3,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 3,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 3,
      start_day: 22,
      end_day: 30,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 4,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 4,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 4,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 4,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 5,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 5,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 5,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 5,
      start_day: 22,
      end_day: 30,
      title: "Respect Authority",
      scripture: "1 Peter 5:5",
      devotion:
        "According to the recent Torah Portion Korach, we can see that when God established someone as a leader, who is holy and honorable, we would do well to respect that authority and not rebel against them.  We also see this in the New Testament in 1 Peter 5:5 - <br><br> \"In the same way, you who are younger, submit yourselves to your elders. All of you, clothe yourselves with humility toward one another, because, 'God opposes the proud but shows favor to the humble.'\" (NIV).<br><br>  With long life, elders have more wisdom, provided they are God-fearing, and we'd do well to listen to these kinds of elders.",
    },
    {
      month: 6,
      start_day: 1,
      end_day: 7,
      title: "The Cursed Serpent & The Cursed Savior",
      scripture: "John 3:14-15",
      devotion:
        'In the recent Torah Portion Chukat, we see how God commands Moses to put a bronze serpent on a "Standard", so that all the disobedient who look upon the serpent will be healed from the curse and live!  Now we know the serpent traditionally represents evil and the Devil, so why would looking on this serpent heal them?  It\'s also strange, making a molded image - which the second commandment apparently forbids - BUT, it says don\'t WORSHIP a molded image.  Other times God does have them make molded images though, such as the cherubim on top of the ark of the covenant.  So let\'s not go off the deep end there. <br><br>But anyway, getting back on track, if the serpent represent evil, why would looking at it heal the people?  Fast forward to Jesus.  In John 3:14 Jesus says something very interesting: <br><br><b>"Just as Moses lifted up the snake in the wilderness, so the Son of Man must be lifted up, that everyone who believes in Him may have eternal life."</b><br><br>Wow! So Jesus is now compared with the serpent!  But Jesus is good, and serpents are bad!  Well..  The serpent was cursed correct? For allowing the devil to use it to entice Adam and Eve to rebel and sin and forfeit eternal life and enter into death spiritually and eventually physically.  But what does Galatians 3:13 say:<br><br> <b>"Christ redeemed us from the curse of the law by becoming a curse for us, for it is written: \'Cursed is everyone who is hung on a pole."</b><br><br>And therein lies the secret. Just as the serpent was cursed, now we have the Messiah Jesus becoming cursed for us on a pole/"standard" as well, and in taking the curse for us, he frees us from the curse of death merited by our sin, as he died FOR our sin!!!<br><br>Thus, let us remember The Cursed Serpent and The Cursed Savior, who took the curse of death so we could live eternally. Hallelu-Yah.',
    },
    {
      month: 6,
      start_day: 8,
      end_day: 14,
      title: "Israel: Blessed though Disciplined",
      scripture: "Romans 11:28",
      devotion:
        "In the recent Torah portion Balak, Balaam is hired to curse Israel, but he finds it's not possible, and He can only bless them as God only allowed blessing, not cursing.<br><br>Now remember, Israel was in a sense under God's judgement at this time as well, because of their faithlessness to take the promised land at the given time - they had to wander in desert 40 years.<br><br>So what does that mean? That even though they were disciplined by God, Israel was still blessed in God's hands because of His mercy, because of His covenant with their fathers, Abraham, Isaac, and Jacob.<br><br>And we see this same concept in the New Testament. Paul says that Israel (as a whole) are enemies for the sake of the gospel - because of their widespread rejection of the gospel and the salvation found in Yeshua Messiah, AND YET they are blessed for the sake of their fathers.<br><br>So it is a paradox. It appears God blesses the physical descendants of Israel partially because of His covenant with Abraham, and yet He is waiting for them to understand the righteousness of Yeshua, who indeed taught the Torah, loving God and neighbor, and who became the ultimate sacrifice for the sin of the world, to once and for all remove sin and bring in everlasting righteousness.<br><br>Thus, let us pray for the natural branches in the olive tree of God's people Israel, that they would be grafted back into their own tree as they embrace the ultimate Jew, Messiah, and Savior, Yeshua.<br><br>Amen.",
    },
    {
      month: 6,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 6,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 7,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 7,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 7,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 7,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 8,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 8,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 8,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 8,
      start_day: 22,
      end_day: 30,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 9,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 9,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 9,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 9,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 10,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 10,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 10,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 10,
      start_day: 22,
      end_day: 30,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 11,
      start_day: 1,
      end_day: 7,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 11,
      start_day: 8,
      end_day: 14,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 11,
      start_day: 15,
      end_day: 21,
      title: "",
      scripture: "",
      devotion: "",
    },
    {
      month: 11,
      start_day: 22,
      end_day: 31,
      title: "",
      scripture: "",
      devotion: "",
    },
  ];

  const date = new Date();

  let month = date.getMonth();
  let day = date.getDate();

  let devotionOfTheWeek1 = [];

  const devotionOfTheWeek = useRef();

  for (let i = 0; i < newTestamentAnnualDevotions.length; i++) {
    if (
      newTestamentAnnualDevotions[i].month === month &&
      day >= newTestamentAnnualDevotions[i].start_day &&
      day <= newTestamentAnnualDevotions[i].end_day
    ) {
      devotionOfTheWeek1.push(newTestamentAnnualDevotions[i]);
    }
  }

  devotionOfTheWeek.current = devotionOfTheWeek1;

  console.log(devotionOfTheWeek);

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2>New Testament Devotional of the Week</h2>
      <h3>Coming ASAP</h3>
      <h4>{devotionOfTheWeek.current[0].title}</h4>
      <h5>{devotionOfTheWeek.current[0].scripture}</h5>
      <h6>
        {new Date().toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </h6>

      <div className="bible-chapters-buttons">
        <div
          className="bible-text"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(devotionOfTheWeek.current[0].devotion),
          }}
        />

        <br></br>
        <br></br>
      </div>
      <br></br>
      <br></br>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    chapter: state.chapter,
    loading: state.loading,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getChapter })(NewTestamentPortion);
