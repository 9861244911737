import React from "react";
import Commentary from "./Commentary";
import OthersCommentary from "./OthersCommentary";
import { connect } from "react-redux";
import loading from "../loading.gif";
import { getChapter } from "../Actions";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

function BibleChapter(props) {
  window.scroll(0, 0);

  const prevChapter = props.chapterNumber - 1;
  const nextChapter = props.chapterNumber + 1;

  const classes = {
    "bible-chapter-button": true,
    autowidth: true,
  };

  return (
    <div>
      <br />
      <br />
      <br />
      <h2>
        {props.outerElement.book} {props.chapterNumber}
      </h2>
      {props.loading ? <img src={loading} alt="Loading" /> : null}
      <div
        className="bible-text"
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.chapter) }}
      />
      <div>{props.error}</div>

      {prevChapter === 0 ? null : (
        <Link
          className="link"
          to={`/${props.outerElement.apibook}${prevChapter}`}
        >
          <button
            onClick={() => {
              props.getChapter(
                `${props.outerElement.apibook}`,
                `${prevChapter}`
              );
            }}
            className={classes}
          >
            <h5>
              {props.outerElement.book} {prevChapter}
            </h5>
          </button>
        </Link>
      )}

      {!(nextChapter - 1 in props.outerElement.chapters) ? null : (
        <Link
          className="link"
          to={`/${props.outerElement.apibook}${nextChapter}`}
        >
          <button
            onClick={() => {
              props.getChapter(
                `${props.outerElement.apibook}`,
                `${nextChapter}`
              );
            }}
            className={classes}
          >
            <h5>
              {props.outerElement.book} {nextChapter}
            </h5>
          </button>
        </Link>
      )}

      <Commentary
        book={props.outerElement.book}
        chapter={props.chapterNumber}
        apibook={props.outerElement.apibook}
      />
      <OthersCommentary
        book={props.outerElement.book}
        chapter={props.chapterNumber}
        apibook={props.outerElement.apibook}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    chapter: state.chapter,
    loading: state.loading,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getChapter })(BibleChapter);