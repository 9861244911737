import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUsers } from '../UserContext';

export default function Wall() {
  const [user, setUser] = useState(null);
  const { username } = useParams();
  const navigate = useNavigate();
  const { users } = useUsers();

  useEffect(() => {
    if (users.length > 0) {
      const foundUser = users.find(u => `${u.first_name}${u.last_name}` === username);
      if (foundUser) {
        setUser(foundUser);
      } else {
        navigate('/users');
      }
    } else {
      navigate('/users');
    }
  }, [username, users, navigate]);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div id="wall">
      <br />
      <br />
      <h3 id="wallname">
        {user.first_name} {user.last_name}'s Wall
      </h3>
      {/* Add more user information or wall content here */}
    </div>
  );
}