import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { axiosWithAuth } from "../axiosWithAuth";
import EditCommentary from "./EditCommentary";
import { connect } from "react-redux";
import genericProfilePic from "../genericprofilepic.png";

function Commentary(props) {
  const userId = localStorage.getItem("user_id");

  const initialCommentary = {
    user_id: userId,
    book: props.book,
    chapter: props.chapter,
    commentary: "",
  };

  const [theCommentary, setTheCommentary] = useState(initialCommentary);
  const [visibleCommentary, setVisibleCommentary] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);

  function handleChange(e) {
    setTheCommentary({
      ...theCommentary,
      [e.target.name]: e.target.value,
    });
  }
  // http://localhost:5000/commentary/
  // https://chaqar-data.herokuapp.com/commentary/
  const postCommentary = (e) => {
    e.preventDefault();
    axios
      .post("https://chaqar-data.herokuapp.com/commentary/", theCommentary)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));

    setTheCommentary({
      user_id: userId,
      book: props.book,
      chapter: props.chapter,
      commentary: "",
    });
  };

  const deleteCommentary = useCallback((id) => {
    axiosWithAuth()
      .delete(`https://chaqar-data.herokuapp.com/commentary/${id}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      axiosWithAuth()
        .get(`https://chaqar-data.herokuapp.com/api/auth/${userId}/commentary`)
        .then((res) => {
          setVisibleCommentary(res.data);
        });
    }, 2000);

    return () => clearTimeout(timer);
  }, [theCommentary, deleteCommentary, userId]);

  const filteredCommentary = visibleCommentary.filter(
    (commentary) =>
      commentary.book === props.book && commentary.chapter === props.chapter
  );

  return (
    <div>
      <br />
      <div id="pic-mycommentary">
      <img
                id="smallprofilepic"
                src={props.profile_pic_url || genericProfilePic}
                alt="profile"
              ></img>
        <br />
        <h3 className="my-commentary-title">My Commentary:</h3>
      </div>
      <div>
        {filteredCommentary.map((commentary) =>
          showEditForm ? (
            <EditCommentary
              setShowEditForm={setShowEditForm}
              key={commentary.id}
              commentary={commentary.commentary}
              commId={commentary.id}
              book={props.book}
              apibook={props.apibook}
              chapter={props.chapter}
            />
          ) : (
            <div className="roundedbox" key={commentary.id}>
              <p className="commentary-text">{commentary.commentary}</p>
              <button onClick={() => setShowEditForm(true)}>Edit</button>
              <button onClick={() => deleteCommentary(commentary.id)}>
                Delete
              </button>
            </div>
          )
        )}
      </div>
      <form onSubmit={postCommentary} id="commentary">
        <textarea
          name="commentary"
          form="commentary"
          value={theCommentary.commentary}
          onChange={handleChange}
        ></textarea>
        <button id="bigbutton" type="submit">
          Save My Commentary
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    first_name: state.first_name,
    last_name: state.last_name,
    profile_pic_url: state.profile_pic_url,
  };
};

export default connect(mapStateToProps, {})(Commentary);
