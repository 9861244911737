import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { setLoggedIn } from "../Actions";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";

const initialLogin = {
  email: "",
  password: "",
};

function Login(props) {
  const [user, setUser] = useState(initialLogin);
  const navigate = useNavigate();

  function handleChange(e) {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  }

  async function subscribeToPushNotifications() {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BEbQ6t6SyYPVBnMPoKywW3aqstASkoR1O7NjMDR9o7ukqfgTOFdLDMhrR8cBGdq53oxnYUJoOKgQG0y0dVmpo-0",
        });

        const userId = localStorage.getItem("user_id");

        const response = await axios.post(
          "https://chaqar-data.herokuapp.com/api/auth/subscribe",
          {
            subscription,
            userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        
        if (response.status === 201) {
          // Show browser notification
          if ("Notification" in window) {
            if (Notification.permission === "granted") {
              new Notification("Login Successful", {
                body: "Welcome Back!",
                icon: "/chaqaricon.png",
              });
            } else if (Notification.permission !== "denied") {
              Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                  new Notification("Login Successful", {
                    body: "Welcome Back! ",
                    icon: "/chaqaricon.png",
                  });
                }
              });
            }
          }
        } else {
          throw new Error("Failed to send subscription to server");
        }
      } catch (error) {
        console.error("Error subscribing to push notifications:", error);
      }
    } else {
      toast.error("Push notifications are not supported in your browser.");
    }
  }
  function login(e) {
    e.preventDefault();
    axios
      .post("https://chaqar-data.herokuapp.com/api/auth/login", user)
      .then((res) => {
        localStorage.setItem("token", JSON.stringify(res.data.token));
        localStorage.setItem("user_id", res.data.user_id);
        props.setLoggedIn();

        // Show toast notification
        toast.success("Logged in successfully!");

        subscribeToPushNotifications();

        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        toast.error("Login failed. Please check your credentials.");
      });

    setUser({
      email: "",
      password: "",
    });
  }

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2>Login</h2>
      <form onSubmit={login}>
        <input
          required
          id="email"
          name="email"
          type="text"
          placeholder="Email"
          value={user.email}
          onChange={handleChange}
        />
        <br></br>
        <input
          required
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          value={user.password}
          onChange={handleChange}
        />
        <br></br>
        <button id="login" type="submit">
          Login
        </button>
      </form>

      <p id="noaccount">
        Don't have an account yet?
        <br></br>
        <Link className="link" to="/register">
          <h5>Register Here</h5>
        </Link>
      </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    first_name: state.first_name,
    last_name: state.last_name,
    loggedIn: state.loggedIn,
  };
};

export default connect(mapStateToProps, { setLoggedIn })(Login);
