import React, { useEffect } from "react";
import { useUsers } from '../UserContext';
import { axiosWithAuth } from "../axiosWithAuth";
import { Link } from "react-router-dom";
import dummyPic from "../blank.png";
const locationStyle = {
  fontSize: "18px",
  fontFamily: "Hebrew",
  marginTop: "0px",
};

const bioStyle = {
  fontSize: "12px",
  fontFamily: "Hebrew",
};


export default function Users() {
  const { users, setUsers } = useUsers();

  useEffect(() => {
    if (users.length === 0) {
      axiosWithAuth()
        .get("https://chaqar-data.herokuapp.com/api/auth")
        .then((res) => {
          setUsers(res.data);
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        });
    }
  }, [users, setUsers]);

  return (
    <div>
      <br></br>
      <br></br>
      <h3>The Community</h3>
      {users.map((user) => (
        <div className="user">
          <div>
            <img
              className="user-pic"
              src={user.profile_pic_url || dummyPic}
              alt="user profile"
            ></img>
          </div>
          <div>
            <p className="user-name">
              {user.first_name} {user.last_name}
            </p>
            <p style={locationStyle}>
              {user.city}, {user.state}, {user.country}
            </p>
            <p style={bioStyle}>{user.bio}</p>
            <div className="smallbrownbutton">
            <Link
                className="smallbrownbuttonlink"
                to={`/user/${user.first_name}${user.last_name}`}
              >
                {user.first_name}'s Wall
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
