import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getName } from "../Actions";
import { axiosWithAuth } from "../axiosWithAuth";
import { Link } from "react-router-dom";
import axios from "axios";
import AddComment from "./AddComment";
import genericProfilePic from "../genericprofilepic.png";

export const uploadProfilePic = () => (dispatch) => {
  const userId = localStorage.getItem("user_id");
  const { files } = document.querySelector('input[type="file"]');
  const formData = new FormData();
  formData.append("file", files[0]);
  formData.append("upload_preset", "ipbfll99");

  axios
    .post(
      "https://api.Cloudinary.com/v1_1/chaqar-bible-app/image/upload",
      formData
    )
    .then((res) => {
      console.log(res.data);
      axiosWithAuth()
        .put(`https://chaqar-data.herokuapp.com/api/auth/${userId}`, {
          profile_pic_url: res.data.secure_url,
        })
        .then((user) =>
          dispatch({
            type: SET_PROFILE_PIC,
            payload: user.data.profile_pic_url,
          })
        );
    })
    .catch((err) => console.log(err));
};

export const getProfilePic = (userId) => (dispatch) => {
  axiosWithAuth()
    .get(`https://chaqar-data.herokuapp.com/api/auth/${userId}`)
    .then((res) => {
      dispatch({
        type: GET_PROFILE_PIC,
        payload: res.data.profile_pic_url,
      });
    });
};

function Dashboard(props) {
  const [userId, setUserId] = useState("");
  const [greeting, setGreeting] = useState("");
  const [weekGreeting, setWeekGreeting] = useState("");
  const [almostShabbat, setAlmostShabbat] = useState(false);

  const [chapterCommentary, setChapterCommentary] = useState([]);
  const [users, setUsers] = useState([]);

  const [posts, setPosts] = useState([]);
  const [postComments, setPostComments] = useState([]);

  const [viewAddComment, setViewAddComment] = useState(false);

  const date = new Date();
  const hour = date.getHours();
  const day = date.getDay();

  useEffect(() => {
    axiosWithAuth()
      .get(`https://chaqar-data.herokuapp.com/commentary/`)
      .then((res) => {
        setChapterCommentary(res.data);
        console.log(res.data);
      });

    axiosWithAuth()
      .get(`https://chaqar-data.herokuapp.com/posts`)
      .then((res) => {
        setPosts(res.data);
      });

    axiosWithAuth()
      .get(`https://chaqar-data.herokuapp.com/comments-on-posts`)
      .then((res) => {
        setPostComments(res.data);
      });

    axiosWithAuth()
      .get("https://chaqar-data.herokuapp.com/api/auth")
      .then((res) => {
        setUsers(res.data);
      });
  }, []);

  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
    props.getName(userId);
    props.getProfilePic(userId);

    function pickGreeting() {
      if (hour >= 5 && hour < 12) {
        return setGreeting("Good Morning");
      }
      if (hour >= 12 && hour < 17) {
        return setGreeting("Good Afternoon");
      }
      if (hour >= 17 && hour < 22) {
        return setGreeting("Good Evening");
      } else {
        return setGreeting("Night Greetings");
      }
    }

    function shabbat_shavua() {
      if (
        (day < 5 && day > 0) ||
        (day === 5 && hour < 18) ||
        (day === 0 && hour >= 18)
      ) {
        return setWeekGreeting(
          `It's a weekday. May Yahweh fill your week with purpose and peace until you enter His rest on the blessed Sabbath day!`
        );
      }
      if ((day === 5 && hour >= 18) || (day === 6 && hour < 18)) {
        return setWeekGreeting(
          `It's the Sabbath today! Shabbat Shalom! - שבת שלום - or... Sabbath Peace to you!`
        );
      }
      if ((day === 6 && hour >= 18) || (day === 0 && hour < 18)) {
        return setWeekGreeting(
          `A new fresh week has started! Shavua Tov! -  שבוע טוב - or... Have a good week!`
        );
      }
    }

    if (day === 4 || (day === 5 && hour < 18)) {
      setAlmostShabbat(true);
    }

    pickGreeting();
    shabbat_shavua();
  }, [props, userId, day, hour]);

  let lastCommentary = chapterCommentary.slice(-3);
  let filteredPosts = posts.slice(-3);
  let filteredPostComments = postComments.slice(-3);

  return (
    <div>
      <br />
      <br />
      <h2 className="h2-greeting">
        {greeting} {props.first_name} {props.last_name}!
      </h2>
      <br />
      <img
          id="profilepic"
          src={props.profile_pic_url || genericProfilePic}
          alt="profile"
      ></img>
      <form>
        <input className="upload-img" type="file" />
        <br />
        <button
          type="button"
          className="btn submit-pic"
          onClick={props.uploadProfilePic}
        >
          Submit New Profile Pic
        </button>
      </form>
      <br />
      <div className="notification">{weekGreeting}</div>
      <br />
      {almostShabbat ? (
        <div className="yellownotification">It's almost... SHABBAT!!!</div>
      ) : null}
      <br />
      <br />
      <br />
      <h3 className="dashboard-header">Engage With The Latest Posts...</h3>
      <div className="dashboard-data">
        <h4 className="data-header">
          The Most Recent Commentary on Bible Chapters:
        </h4>
        <div>
          {lastCommentary.map((commentary) =>
            users.map((user) => {
              if (user.id === commentary.user_id) {
                return (
                  <div className="roundedbox2" key={commentary.id}>
                    <p className="commentary-text">
                      <div>
                        {user.profile_pic_url != null ? (
                          <div>
                            <img
                              id="smallprofilepic"
                              src={user.profile_pic_url}
                              alt={`${user.first_name} ${user.last_name}'s profile`}
                            />
                            <br />
                          </div>
                        ) : null}
                        <b>
                          {user.first_name} {user.last_name} commented on{" "}
                          {commentary.book} {commentary.chapter}:
                        </b>
                      </div>
                    </p>
                    <p className="commentary-text">{commentary.commentary}</p>
                  </div>
                );
              }
              return null;
            })
          )}
        </div>
        <Link
          to={{ pathname: "/read", hash: "#books" }}
          onClick={() => window.scrollTo(0, 650)}
        >
          <div className="action-button">Go to Bible Books</div>
        </Link>
      </div>

      <div className="dashboard-data-2">
        <h4 className="data-header">The Most Recent Posts in The Forum:</h4>

        {filteredPosts.map((post) =>
          users.map((user) => {
            if (user.id === post.user_id) {
              return (
                <div className="roundedbox" key={post.id}>
                  <p className="commentary-text">
                    {user.profile_pic_url != null ? (
                      <div>
                        <img
                          id="mycommentarypic"
                          src={user.profile_pic_url}
                          alt={`${user.first_name} ${user.last_name}'s profile`}
                        />
                      </div>
                    ) : null}
                    <b>
                      {user.first_name} {user.last_name} posted in{" "}
                      {post.discussion_room}:
                    </b>
                  </p>
                  <p className="commentary-text">{post.post}</p>
                  {!viewAddComment ? (
                    <button
                      className="respond-btn"
                      onClick={() => setViewAddComment(true)}
                    >
                      Respond
                    </button>
                  ) : (
                    <AddComment
                      setViewAddComment={setViewAddComment}
                      post_id={post.id}
                      discussionroom={post.discussion_room}
                    />
                  )}

                  <div id="comments">
                    <br />
                    <br />
                    <h4 id="comments-title">Comments</h4>
                    {postComments
                      .filter((comment) => post.id === comment.post_id)
                      .map((comment) =>
                        users.map((commentuser) => {
                          if (commentuser.id === comment.user_id) {
                            return (
                              <div key={comment.id}>
                                {commentuser.profile_pic_url != null ? (
                                  <div>
                                    <img
                                      id="smallprofilepic"
                                      src={commentuser.profile_pic_url}
                                      alt={`${commentuser.first_name} ${commentuser.last_name}'s profile`}
                                    />
                                  </div>
                                ) : null}
                                <p className="commentary-text">
                                  <b>
                                    {commentuser.first_name}{" "}
                                    {commentuser.last_name} Responded:
                                  </b>
                                </p>
                                <p className="commentary-text">
                                  {comment.comment}
                                </p>
                                <br />
                                <br />
                              </div>
                            );
                          }
                          return null;
                        })
                      )}
                  </div>
                </div>
              );
            }
            return null;
          })
        )}
        <Link to="/theforum" onClick={() => window.scrollTo(0, 0)}>
          <div className="action-button">Go to The Forum</div>
        </Link>
      </div>

      <div className="dashboard-data-3">
        <h4 className="data-header">
          The Most Recent Replies to Posts in The Forum:
        </h4>
        <div>
          {filteredPostComments.map((postComment) =>
            users.map((user) => {
              if (user.id === postComment.user_id) {
                return (
                  <div className="roundedbox3" key={postComment.id}>
                    <p className="commentary-text">
                      <div>
                        {user.profile_pic_url != null ? (
                          <div>
                            <img
                              id="smallprofilepic"
                              src={user.profile_pic_url}
                              alt={`${user.first_name} ${user.last_name}'s profile`}
                            />
                            <br />
                          </div>
                        ) : null}
                        <b>
                          {user.first_name} {user.last_name} replied to a post
                          in{" "}
                          {postComment.discussion_room[0].toUpperCase() +
                            postComment.discussion_room.substring(1)}{" "}
                          discussion room of The Forum:
                        </b>
                      </div>
                    </p>
                    <p className="commentary-text">{postComment.comment}</p>
                  </div>
                );
              }
              return null;
            })
          )}
        </div>
        <Link to="/theforum" onClick={() => window.scrollTo(0, 650)}>
          <div className="action-button">Go to The Forum</div>
        </Link>
      </div>
      <p className="mediumtext">See who else is studying with you in...</p>
      <div className="brownroundbutton">
        <Link className="brownbuttonlink" to="/users">
          The Community
        </Link>
      </div>
      <br />
      <br />
      <p className="mediumtext">
        Want to be inspired and more greatly filled with the Spirit as you study
        God's word? Why not listen to some devotional background music as you
        study?
      </p>
      <div className="brownroundbutton">
      <button
        onClick={() => {
          window.scrollTo(0, 0);
          props.setMusicDisplayed(true);
        }}
        className="brownbuttonlink"
      >
        Play Music
      </button>

      </div>
      <br />
      <br />
      <p className="mediumtext">
        Learn more about the scriptures and how they apply to us with...
      </p>
      <div className="brownroundbutton">
        <Link className="brownbuttonlink" to="/videos">
          Video Teachings
        </Link>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export const SET_PROFILE_PIC = "SET_PROFILE_PIC";
export const GET_PROFILE_PIC = "GET_PROFILE_PIC";

const mapStateToProps = (state) => {
  return {
    first_name: state.first_name,
    last_name: state.last_name,
    profile_pic_url: state.profile_pic_url,
    profile_pic_alt: state.profile_pic_alt,
  };
};

export default connect(mapStateToProps, {
  getName,
  uploadProfilePic,
  getProfilePic,
})(Dashboard);
