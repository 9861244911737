import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import DOMPurify from "dompurify";

function TorahPortion() {
  const [loading, setLoading] = useState(true);
  const [dailyInfo, setDailyInfo] = useState(null);
  const [parasha, setParasha] = useState(null);  // eslint-disable-line no-unused-vars
  const [parashaText, setParashaText] = useState([]);
  const [hebrewText, setHebrewText] = useState([]);

  const processParashaText = useCallback((text) => {
    const chapterSequence = [
      "First",
      "Second",
      "Third",
      "Fourth",
      "Fifth",
      "Sixth",
      "Seventh",
    ];

    const processed = [];
    const isNestedArray = Array.isArray(text[0]);

    if (isNestedArray) {
      text.forEach((chapter, index) => {
        processed.push(`<br><h5 class="chapter-sequence">${chapterSequence[index]} Chapter</h5>`);
        processed.push(...chapter);
      });
    } else {
      processed.push(`<br><h5 class="chapter-sequence">${chapterSequence[0]} Chapter</h5>`);
      processed.push(...text);
    }

    return processed;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const calendarResponse = await axios.get("https://www.sefaria.org/api/calendars");
        setDailyInfo(calendarResponse.data);

        const parashaResponse = await axios.get(
          `https://www.sefaria.org/api/texts/${calendarResponse.data.calendar_items[0].url}`
        );
        setParasha(parashaResponse.data);

        const processedText = processParashaText(parashaResponse.data.text);
        const processedHebrewText = processParashaText(parashaResponse.data.he);

        setParashaText(processedText);
        setHebrewText(processedHebrewText);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [processParashaText]);

  const renderText = (textArray) => {
    return textArray.map((verse, index) => (
      <div
        key={index}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(verse),
        }}
      />
    ));
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <br></br><br></br>
      <h2>Torah Portion of the Week</h2>
      {dailyInfo && (
        <div>
          <h4>
            Parashat {dailyInfo.calendar_items[0].displayValue.en} or{" "}
            {dailyInfo.calendar_items[0].displayValue.he}
          </h4>
          <h5>{dailyInfo.calendar_items[0].ref}</h5>
        </div>
      )}
      <h6>
        {new Date().toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </h6>

      <div className="bible-chapters-buttons">
        <div className="bible-text">{renderText(parashaText)}</div>
        <hr className="hr" />
        <h4>In Hebrew:</h4>
        <div className="bible-text-hebrew">{renderText(hebrewText)}</div>
      </div>
      <br></br><br></br>
    </div>
  );
}

export default TorahPortion;