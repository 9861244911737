import React from "react";
import { connect } from "react-redux";
import { getChapter } from "../Actions";
import { Link } from "react-router-dom";

function BibleBook(props) {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2>{props.outerElement.book}</h2>

      <div className="bible-chapters-buttons">
        {props.outerElement.chapters.map((chapter) => (
          <button
            key={chapter}
            onClick={() => {
              props.getChapter(props.outerElement.apibook, chapter);
            }}
            className="bible-chapter-button"
          >
            <Link
              className="link"
              to={`/${props.outerElement.apibook}${chapter}`} 
            >
              <h5>{chapter}</h5>
            </Link>
          </button>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  chapter: state.chapter,
  loading: state.loading,
  error: state.error,
});

export default connect(mapStateToProps, { getChapter })(BibleBook);
