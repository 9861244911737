import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getName } from '../Actions'
import axios from 'axios'
import { axiosWithAuth } from '../axiosWithAuth'
import EditProfile from './EditProfile'

import genericProfilePic from '../genericprofilepic.png'

export const uploadProfilePic = () => dispatch => {
    const userId = localStorage.getItem("user_id")
    const { files } = document.querySelector('input[type="file"]')
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('upload_preset', 'ipbfll99')

    axios
        .post('https://api.Cloudinary.com/v1_1/chaqar-bible-app/image/upload', formData)
        .then(res => {
            console.log(res.data)
            axiosWithAuth()
                .put(`https://chaqar-data.herokuapp.com/api/auth/${userId}`, { profile_pic_url: res.data.secure_url })
                .then(user =>
                    dispatch({
                        type: SET_PROFILE_PIC,
                        payload: user.data.profile_pic_url
                    })
                )
        })
        .catch(err => console.log(err))
}

export const getProfilePic = (userId) => dispatch => {
    axiosWithAuth()
        .get(`https://chaqar-data.herokuapp.com/api/auth/${userId}`)
        .then((res) => {
            dispatch({
                type: GET_PROFILE_PIC,
                payload: res.data.profile_pic_url
            })
        })
}

function MyProfile({ getProfilePic, getName, uploadProfilePic, profile_pic_url }) {
    const [userId, setUserId] = useState('')
    const [users, setUsers] = useState([])
    const [editProfile, setEditProfile] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => {
            const id = localStorage.getItem("user_id")
            setUserId(id)
            getName(id)

            axiosWithAuth()
                .get('https://chaqar-data.herokuapp.com/api/auth')
                .then((res) => {
                    setUsers(res.data)
                })
        }, 1200)

        return () => clearTimeout(timer)
    }, [editProfile, getName, userId])

    useEffect(() => {
        getProfilePic(userId)
    }, [userId, getProfilePic])

    return (
        <div>
            <br /><br />
            <h2>My Profile</h2>
            <br /><br />
            {profile_pic_url != null ?
                <img src={profile_pic_url} id="profilepic" alt="User's Profile" />
                : <img id="genericProfilePic" src={genericProfilePic} alt="Generic Profile" />
            }
            <form>
                <input type="file" /><br />
                <button type="button" className="btn" onClick={uploadProfilePic}>Submit</button>
            </form>
            <br />
            {users.map(user => {
                if (JSON.stringify(user.id) === userId) {
                    return (
                        editProfile ?
                            <EditProfile key={user.id} user={user} setEditProfile={setEditProfile} />
                            :
                            <div key={user.id}>
                                <div className="roundedbox4">
                                    <p className="profiletext">
                                        <b>Name:</b>
                                        <br />
                                        {user.first_name} {user.last_name}
                                    </p>
                                </div>
                                <div className="roundedbox4">
                                    <p className="profiletext">
                                        <b>Location:</b>
                                        <br />
                                        {user.city}, {user.state}, {user.country}
                                    </p>
                                </div>
                                <div className="roundedbox4">
                                    <p className="profiletext">
                                        <b>Bio:</b>
                                        <br />
                                        <p className="commentary-text">{user.bio}</p>
                                    </p>
                                </div>
                                <button onClick={() => setEditProfile(true)}>Edit Profile</button>
                            <br />
                            <br />
                            </div>
                            
                    )
                }
                return null; // Ensure that map returns a value
            })}
        </div>
    )
}

export const SET_PROFILE_PIC = 'SET_PROFILE_PIC'
export const GET_PROFILE_PIC = 'GET_PROFILE_PIC'

const mapStateToProps = state => {
    return {
        first_name: state.first_name,
        last_name: state.last_name,
        profile_pic_url: state.profile_pic_url,
        profile_pic_alt: state.profile_pic_alt
    }
}

export default connect(mapStateToProps, { getName, uploadProfilePic, getProfilePic })(MyProfile)
