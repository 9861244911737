import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import axios from "axios";
import { setLoggedIn } from "../Actions";
import { connect } from "react-redux";
import { toast } from "react-hot-toast";

const initialRegister = {
  first_name: "",
  last_name: "",
  email: "",
  password: "",
};

function Register(props) {
  const [newUser, setNewUser] = useState(initialRegister);
  const navigate = useNavigate(); 

  function handleChange(e) {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  }

  async function subscribeToPushNotifications(userId) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      try {
        const registration = await navigator.serviceWorker.ready;
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: "BEbQ6t6SyYPVBnMPoKywW3aqstASkoR1O7NjMDR9o7ukqfgTOFdLDMhrR8cBGdq53oxnYUJoOKgQG0y0dVmpo-0",
        });

        await axios.post(
          "https://chaqar-data.herokuapp.com/api/auth/subscribe",
          {
            subscription,
            userId,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Push notification subscription sent to server successfully");
        toast.success("Subscribed to push notifications!");
      } catch (error) {
        console.error("Error subscribing to push notifications:", error);
        toast.error("Failed to subscribe to push notifications.");
      }
    } else {
      console.log("Push notifications are not supported");
      toast.error("Push notifications are not supported in your browser.");
    }
  }

  const registerAndLogin = async (e) => {
    e.preventDefault();
    try {
      // http://localhost:5000/api/auth/register
      // https://chaqar-data.herokuapp.com/api/auth/register
      const res = await axios.post("https://chaqar-data.herokuapp.com/api/auth/register", newUser);
      localStorage.setItem("token", JSON.stringify(res.data.token));
      localStorage.setItem("user_id", res.data.user_id);
      props.setLoggedIn();
      // Subscribe to push notifications
      await subscribeToPushNotifications(res.data.user_id);
      navigate("/dashboard");
    } catch (err) {
      console.log(err);
      toast.error("Registration failed. Please try again.");
    }

    setNewUser(initialRegister);
  };

  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <h2>Register</h2>
      <form onSubmit={registerAndLogin}>
        <input
          required
          id="first-name"
          name="first_name"
          type="text"
          placeholder="First Name"
          value={newUser.first_name}
          onChange={handleChange}
        />
        <br></br>
        <input
          required
          id="last-name"
          name="last_name"
          type="text"
          placeholder="Last Name"
          value={newUser.last_name}
          onChange={handleChange}
        />
        <br></br>
        <input
          required
          id="email"
          name="email"
          type="text"
          placeholder="Email"
          value={newUser.email}
          onChange={handleChange}
        />
        <br></br>
        <input
          required
          id="password"
          name="password"
          type="password"
          placeholder="Password"
          value={newUser.password}
          onChange={handleChange}
        />
        <br></br>
        <button id="login" type="submit">
          Register & Login
        </button>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    first_name: state.first_name,
    last_name: state.last_name,
    loggedIn: state.loggedIn,
  };
};

export default connect(mapStateToProps, { setLoggedIn })(Register);
