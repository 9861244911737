import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { connect } from "react-redux";
import { getChapter } from "../Actions";

function HaftarahPortion() {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [dailyInfo, setDailyInfo] = useState({});
  const [haftarahText, setHaftarahText] = useState([]);
  const [hebrewText, setHebrewText] = useState({});

  const text = useMemo(() => [], []);
  const hebtext = useMemo(() => [], []);
  const chapterSequence = useMemo(
    () => ["First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh"],
    []
  );

  useEffect(() => {
    axios.get("https://www.sefaria.org/api/calendars").then((res) => {
      setDailyInfo(res.data);
      setLoading(false);

      axios
        .get(
          `https://www.sefaria.org/api/texts/${res.data.calendar_items[1].url}`
        )
        .then((res) => {
          if (res.data.text.length > 8) {
            for (let k = 0; k < res.data.text.length; k++) {
              if (k === 0) {
                text.push(
                  `<br><h5 class="chapter-sequence">${chapterSequence[k]} Chapter</h5>`
                );
                text.push(res.data.text[k]);
              } else {
                text.push(res.data.text[k]);
              }
            }

            for (let l = 0; l < res.data.he.length; l++) {
              if (l === 0) {
                hebtext.push(
                  `<br><h5 class="chapter-sequence">${chapterSequence[l]} Chapter</h5>`
                );
                hebtext.push(res.data.he[l]);
              } else {
                hebtext.push(res.data.he[l]);
              }
            }
          } else {
            for (let m = 0; m < res.data.text.length; m++) {
              for (let n = 0; n < res.data.text[m].length; n++) {
                if (n === 0) {
                  text.push(
                    `<br><h5 class="chapter-sequence">${chapterSequence[m]} Chapter</h5>`
                  );
                  text.push(res.data.text[m][n]);
                } else {
                  text.push(res.data.text[m][n]);
                }
              }
            }

            for (let o = 0; o < res.data.he.length; o++) {
              for (let p = 0; p < res.data.he[o].length; p++) {
                if (p === 0) {
                  hebtext.push(
                    `<br><h5 class="chapter-sequence">${chapterSequence[o]} Chapter</h5>`
                  );
                  hebtext.push(res.data.he[o][p]);
                } else {
                  hebtext.push(res.data.he[o][p]);
                }
              }
            }
          }

          setHaftarahText(text);
          setHebrewText(hebtext);
          setLoading2(false);
        });
    });
  }, [chapterSequence, hebtext, text]);

  return (
    <div>
      <br />
      <br />
      <br />
      <h2>Haftarah Portion of the Week</h2>
      {loading ? (
        ""
      ) : (
        <div>
          <h4>
            {dailyInfo.calendar_items[1].displayValue.en} or{" "}
            {dailyInfo.calendar_items[1].displayValue.he}
          </h4>
        </div>
      )}
      <h6>
        {new Date().toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </h6>

      <div className="bible-chapters-buttons">
        {loading2
          ? ""
          : haftarahText.map((verse, index) => (
              <div
                key={index}
                className="bible-text"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(verse),
                }}
              />
            ))}
        <br />
        <hr className="hr" />
        <br />
        <br />
        <br />
        <h4>In Hebrew:</h4>
        <br />
        {loading2
          ? ""
          : hebrewText.map((verse, index) => (
              <div
                key={index}
                className="bible-text-hebrew"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(verse),
                }}
              />
            ))}
        <div style={{ paddingTop: 40, width: "100%", display: "block" }}></div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    chapter: state.chapter,
    loading: state.loading,
    error: state.error,
  };
};

export default connect(mapStateToProps, { getChapter })(HaftarahPortion);
