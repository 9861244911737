import React, { useState } from "react";
import "../App.css";
import { setLoggedIn } from "../Actions";
import { connect } from "react-redux";

function Music(props) {
  const [hideMusic, setHideMusic] = useState(false);

  return (
    <>
      <div id="music">
        <iframe
          id={hideMusic ? "iframeclosed" : "iframeopen"}
          src="https://messianicradio.com/"
          title="Messianic Radio"
        />
        {hideMusic ? (
          <div className="brownbar">
            <button onClick={() => setHideMusic(false)} className="brownbuttonlink">
              Show Music
            </button>
          </div>
        ) : (
          <div className="brownbar">
            <button onClick={() => setHideMusic(true)} className="brownbuttonlink">
              Hide Music
            </button>
          </div>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    first_name: state.first_name,
    last_name: state.last_name,
    loggedIn: state.loggedIn,
  };
};

export default connect(mapStateToProps, { setLoggedIn })(Music);
